import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Button, Layout, Menu, Row, Col } from 'antd';

const { Header } = Layout;

const items = [
  {
    label: <Link href="/">Home</Link>,
    key: 'home',
  }
]
const PageHeader = () => {
  const router = useRouter();
  const { status } = useSession();

  if (status !== 'authenticated') {
    return <Header />
  }

  return (
    <Header>
      <Row>
        <Col flex="auto">
          <Menu theme="dark" mode="horizontal" activeKey={router.pathname} items={items} />
        </Col>

        <Col flex="80px">
          <Button onClick={() => signOut()}>
            Logout
          </Button>
        </Col>
      </Row>
    </Header>
  );
};

export default PageHeader;
