import Head from 'next/head';
import { AppProps } from 'next/app';
import { Layout, BackTop } from 'antd';
import { SessionProvider } from "next-auth/react"
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../lib/apollo'

import 'antd/dist/antd.css';

import '../styles/globals.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

const { Content } = Layout;

export default function LocaleEditorApp({ Component, pageProps: { session, ...pageProps }, }: AppProps<any>) {
  const apolloClient = useApollo(pageProps.initialApolloState)

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta name="description" content="Locale editor" />
        <meta name="keywords" content="locale editor" />
        <title>Locale editor</title>

        <link rel="manifest" href="/manifest.json" />
        <link
          href="/icons/icon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icons/icon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/apple-icon.png"></link>
        <meta name="theme-color" content="#317EFB" />
      </Head>
      <BackTop />
      <ApolloProvider client={apolloClient}>
        <SessionProvider session={session}>
          <Layout>
            <Header />
            <Content>
              <Component {...pageProps} />
            </Content>
            <Footer />
          </Layout>
        </SessionProvider>
      </ApolloProvider>
    </>
  );
}
