import { IncomingMessage, ServerResponse } from 'http'
import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicies,
  gql,
} from '@apollo/client'
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { SchemaLink } from '@apollo/client/link/schema';
import { GraphQLSchema } from 'graphql';
import { JWT } from 'next-auth/jwt';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

interface Dataloader<T> {
  load: (id: string) => Promise<T>;
}

export type ResolverContext = {
  req?: IncomingMessage,
  res?: ServerResponse,
  session?: JWT,
}

function createIsomorphLink(context: ResolverContext = {}) {
  if (typeof window === 'undefined') {
    const schema: GraphQLSchema = require('../graphql/schema');
    return new SchemaLink({ schema, context })
  } else {
    // @TODO: add subscriptions
    // const { WebSocketLink } = require('@apollo/client/link/ws');
    return new BatchHttpLink({
      uri: '/api/graphql',
      batchMax: 10,
    })
  }
}

interface IRef {
  __ref: string;
}

const typePolicies: TypePolicies = {};

const typeDefs = gql`
  extend type Query {
    gamesPerPage: Int!
  }
`;

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: typeof window === 'undefined',
    link: createIsomorphLink(context),
    cache: new InMemoryCache({ typePolicies }),
    typeDefs,
  })
}

export function initializeApollo(
  initialState: NormalizedCacheObject,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
